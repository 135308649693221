<template>
	<div class="offer-wrapper">
		<grid-template cols="2">
			<template #title>
				<base-font variant="heading" class="offer-title" size="xl" tag="h1" color="secondary" v-animate="`slideLeft`">{{ offer.name[lang]}}</base-font>
			</template>
			<div class="offer-items">
            <div class="offer-content" v-animate="`opacity`">
                <base-font variant="small-heading" class="offer-subheading" color="secondary">{{ translate.expectations }}:</base-font>
                <div class="offer-description" v-html="data.expectations"></div>
            </div>
            <div class="offer-content" v-animate="`opacity`">
                <base-font variant="small-heading" class="offer-subheading" color="secondary">{{ translate.job_conditions }}:</base-font>
                <div class="offer-description" v-html="data.conditions"></div>
            </div>
            <div class="offer-content" v-animate="`opacity`">
                <base-font variant="small-heading" class="offer-subheading" color="secondary">{{ translate.duties }}:</base-font>
                <div class="offer-description" v-html="data.responsibilities"></div>
            </div>
            <div class="clause-information" v-html="data.clause" v-animate="`opacity`"></div>
			</div>
			<div class="offer-attributes">
				<div class="offer-attributes-items">
					<div class="offer-item" v-for="(item, index) in advantages" :key="index" v-animate="`opacity`" >
						<base-svg class="icon" :name="`${item.icon}`"/>
						<base-font color="primary" class="item-desc" uppercase>{{item.description}}</base-font>
					</div>
				</div>
                <div class="offer-application" v-background="background">
                    <div class="offer-application-content">
                        <div class="offer-application-section">
                            <base-font variant="paragraph" color="white">{{translate.application_mail}}:</base-font>
                            <base-link class="offer-email" type="text" title="Adres e-mail do firmy" :url="`mailto:${generalSettings.contact.recrutationEmail}`">{{generalSettings.contact.recrutationEmail}},</base-link>
                        </div>
                        <div class="offer-application-data">
                            <base-font variant="paragraph" color="white">{{translate.application_address}}:</base-font>
                            <div class="contact-info">
                                <base-font variant="paragraph" color="white">{{generalSettings.contact.streetAddress}},</base-font>
                                <base-link class="offer-email" type="text" :url="`tel:${generalSettings.contact.phoneNumber}`" title="Telefon kontaktowy do firmy"> tel:{{generalSettings.contact.phoneNumber}}</base-link>
                            </div>
                        </div>
                    </div>
                    <base-button icon="envelope" :url="`mailto:${generalSettings.contact.recrutationEmail}`" type="primary" title="Zaaplikuj i dołącz do naszej firmy" class="offer-button">{{translate.application_text}}</base-button>
                </div>
			</div>
			<template #buttons>
				<base-button icon="stack" to="career" type="secondary" title="Powrót do strony z ofertami pracy" class="back-btn">{{translate.back_button}}</base-button>
			</template>
		</grid-template>
	</div>
</template>

<script>
import BaseButton from '~/website/front/components/atoms/BaseButton/BaseButton.vue'
import BaseFont from '~/website/front/components/atoms/BaseFont/BaseFont.vue'
import BaseLink from '~/website/front/components/atoms/BaseLink/BaseLink.vue'
import GridTemplate from '~/website/front/templates/GridTemplate.vue'
import { pageMixin } from '~/website/front/core/pages/page.mixin'
export default {
	components: {
		GridTemplate,
		BaseLink,
		BaseFont,
		BaseButton
	},
	mixins: [pageMixin],
	computed: {
		translate () {
			return this.$app.translator.get('career')
		},
		offer () {
			return this.$app.page.jobOffer
		},
		data () {
			return this.$app.page.value
		},
		advantages () {
			return this.$app.page.jobOffer.data[this.lang]
		},
		lang () {
			return this.$app.language
		},
		generalSettings () {
			return this.$app.settings.general
		},
		background () {
			const path = this.offer.thumbnail.path

			return {
				src: path,
				breakpoints: {
					base: { width: 500, height: 300 },
					lg: { width: 680, height: 204 }
				}
			}
		}
	}
}
</script>

<style scoped lang="scss">
.offer-wrapper {
	position: relative;
    background: $gray-300;
    padding: 10rem 0 4rem 0;
	@include media-breakpoint-up(xl) {
		padding: 16rem 0 8rem 0;
        background: $white;
	}
	.back-btn {
		margin-top: 3rem;

		@include media-breakpoint-up(xl) {
			margin-top: 6rem;
		}
	}
}

::v-deep .grid-wrapper {
    @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(2, 1fr);
    }
    grid-template-columns: repeat(1, 1fr);
}

.offer {
    &-content {
        margin-bottom: 2rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &-application {
        background-size: cover;
        background-repeat: no-repeat;
    }
	::v-deep &-title {
		margin-bottom: 4rem;
	}
	&-attributes-items {
		display: grid;
		background: $gray-300;
		gap: 2rem;
        padding: 2rem 0;
        grid-template-columns: repeat(2, 1fr);
        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include media-breakpoint-up(xl) {
            padding: 5rem;
        }
	}
	&-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: $white;
        padding: 1rem;
        @include media-breakpoint-up(md) {
            padding: 2rem;
        }
        @include media-breakpoint-up(xl) {
            &:hover {
                .svg-icon {
                    transform: translateY(-5px);
                }
            }
        }
		.svg-icon {
			fill: $secondary;
            height: 35px;
            width: 35px;
            @include transition;
            @include media-breakpoint-up(md) {
                height: 50px;
                width: 50px;
            }
		}
        ::v-deep .item-desc {
            text-align: center;
            font-size: 1.3rem;
            line-height: 1.5rem;
            margin-top: 1rem;
            @include media-breakpoint-up(md) {
                margin-top: 3rem;
                font-size: 1.4rem;
                line-height: 1.8rem;
            }
        }
	}
    &-items {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    ::v-deep &-subheading {
        margin-bottom: 1rem;
    }
    ::v-deep &-description {
        ul {
            margin: 0;
            padding-left: 2rem;
            li {
                font-size: 1.6rem;
                letter-spacing: 0.04em;
                line-height: 2.2rem;
            }
        }
    }
    &-application {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        padding: 4rem 3rem;
        justify-content: space-between;
        flex-direction: column;
        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
        &::before {
            content: '';
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            background: rgba($secondary, .7);
            z-index: -1;

        }
        &-section {
            margin-bottom: 2rem;
        }
        &-content {
            margin-bottom: 2rem;
            text-align: center;
            @include media-breakpoint-up(md) {
                margin-bottom: 0;
                text-align: left;
            }
        }
    }
    ::v-deep &-email {
        color: $white;
        text-transform: none;
        font-weight: 400;
    }
}
.contact-info {
    display: flex;
    justify-content: center;
    @include media-breakpoint-up(md) {
        justify-content: flex-start;
    }
}
::v-deep .clause-information {
        margin-top: 2rem;
        line-height: 1.8rem;
        font-weight: 400;
        letter-spacing: 0.04em;
    }
</style>
