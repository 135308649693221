<template>
	<base-container>
		<div>
			<slot name="title"/>
		</div>
		<div class="grid-wrapper cols" :class="[`cols-${cols}`, `${className}`]">
			<slot/>
		</div>
		<slot name="buttons" v-if="$slots.buttons"/>
	</base-container>
</template>
<script>
export default {
	props: {
		cols: {
			type: String,
			default: '2'
		},
		className: {
			type: String,
			default: ''
		}
	}
}
</script>
<style lang="scss" scoped>
.grid-wrapper {
	display: grid;
	gap: 2rem;
	@include media-breakpoint-landscape {
		place-items: center;
	}
	@include media-breakpoint-up(xl) {
		gap: 3rem;
	}
}
.cols {
	@include media-breakpoint-up(md) {
		grid-template-columns: repeat(2, 1fr);
	}
	&-3 {
		@include media-breakpoint-up(md) {
			grid-template-columns: repeat(3, 1fr);
		}
	}
	&-4 {
		@include media-breakpoint-up(xl) {
			grid-template-columns: repeat(4, 1fr);
		}
	}
	&-5 {
		@include media-breakpoint-up(md) {
			grid-template-columns: repeat(3, 1fr);
		}
		@include media-breakpoint-up(xl) {
			grid-template-columns: repeat(5, 1fr);
		}
	}
}
.buttons-wrapper {
	display: flex;
    gap: 2rem;
    align-items: flex-start;
    flex-direction: column;
	margin-top: 4rem;

	@include media-breakpoint-up(md) {
		flex-direction: row;
		justify-content: center;
		gap: 3rem;
	}
	@include media-breakpoint-up(lg) {
		margin-top: 6rem;
	}
}
</style>
