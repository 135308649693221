import { Page } from '~/cms/front/core/pages/Page'
import JobOfferPageComponent from './JobOfferPage.vue'

class JobOfferPage extends Page {
	static alias = 'offer'

	async init () {
		await super.init(...arguments)
		await this.app.translator.prefetch('career')
	}

	component = JobOfferPageComponent
	jobOffer = {}

	constructor ({ app, route, from }) {
		super({ app, route, from })
	}

	async fetchData () {
		const JobOffer = this.app.getEntity('job-offer')
		this.jobOffer = await JobOffer.fetchOne(this.alias)
		if (!this.jobOffer) return this.app.vue.$redirect({ path: '/404' })
		await this.setPageData(this.jobOffer.page)
	}

	get header () {
		return {
			title: this.value.header ? this.value.header : this.jobOffer.name
		}
	}

	restore () {
		super.restore()
		const JobOffer = this.app.getEntity('job-offer')
		this.jobOffer = JobOffer.create(window.__PAGE__.jobOffer)
		this.value = window.__PAGE__.jobOffer.page.value
	}

	store () {
		const store = super.store()
		delete store.value
		store.jobOffer = this.jobOffer
		return store
	}
}

export { JobOfferPage }
